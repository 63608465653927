<!--
 * @Description: 人行道 运营管理 停车监管 parkingRegulation
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-17 09:53:19
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="lineOneTop"></el-row>
    <el-row class="lineOne">
      <el-row class="lineOneLeft">
        <el-row class="lineOneLeftTitle">
          <span class="lineOneLeftTitleLeft">选择停车场:</span>
          <el-select filterable
                     :disabled="topParkIdDisable"
                     v-model="topParkId"
                     placeholder="全部"
                     style="width:256px;margin-left:10px"
                     @change="topSelectChange">
            <el-option v-for="(item, index) in parkNameList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <span class="lineOneLeftTitleLeft"
                style="margin-left:20px">进场时间:</span>
          <el-row>
            <el-date-picker v-model="entranceTime"
                            @change="handleChangeEntranceTime"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                            :picker-options="pickerOptions">
            </el-date-picker>
          </el-row>
        </el-row>
        <el-row class="lineOneLeftContent">
          <el-image style="width:100%;height:100%;"
                    v-if="inEvidencePic"
                    :src="$imgBaseUrl + inEvidencePic"
                    :preview-src-list="insrcList"
                    :fit="fit">
          </el-image>
          <div v-else
               style="width:100%;height:100%;display: flex; align-items: center;justify-content: center;">暂无图片</div>
        </el-row>
      </el-row>
      <el-row class="lineOneMiddle">
        <el-row style="display:flex">
          <span class="lineOneLeftTitleLeft">车牌号：</span>
          <el-input v-model="plateNumber"
                    style="width:200px"
                    clearable
                    @change="handleChangePlateNumebr"
                    placeholder="请输入"></el-input>
          <el-button type="primary"
                     style="margin-left:20px"
                     @click="handleClickAlterCome">进场补录</el-button>
        </el-row>
        <el-row style="margin-top:3%;height:90%">
          <el-scrollbar>
            <el-col :span="8"
                    v-for="(item,index) in middleOrderList"
                    :key="index"
                    class="parkSpaceDiv">
              <el-row class="topImg"
                      @click.native="handleClickParkSpaceItem(item)">
                <el-image v-if="item.evidencePic"
                          style="width:100%;height:100%;"
                          :src="$imgBaseUrl + item.evidencePic"
                          :fit="fit">
                </el-image>
                <div v-else
                     style="width:100%;height:100%;display: flex; align-items: center;justify-content: center;">暂无图片</div>
              </el-row>
              <el-row class="bottomText">{{item.plateColor}}-{{item.plateNumber}}</el-row>
            </el-col>
          </el-scrollbar>
        </el-row>
      </el-row>
      <el-row class="lineOneRight">
        <el-row class="lineOneRightTitle">
          订单详情
        </el-row>
        <el-row class="lineRowItem">
          <el-row class="lineRowTitle">订单编号：</el-row>
          <el-row class="lineRowContent">{{orderList.orderSequence}}</el-row>
        </el-row>
        <el-row class="lineRowItem">
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">车牌号：</el-row>
            <el-row class="lineRowContent">{{orderList.plateNumber}}</el-row>
          </el-col>
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">车牌颜色：</el-row>
            <el-row class="lineRowContent">{{orderList.plateColor}}</el-row>
          </el-col>
        </el-row>
        <el-row class="lineRowItem">
          <el-row class="lineRowTitle">进场时间：</el-row>
          <el-row class="lineRowContent">{{orderList.entranceTime}}</el-row>
        </el-row>
        <el-row class="lineRowItem">
          <el-row class="lineRowTitle">停车时长：</el-row>
          <el-row class="lineRowContent">{{orderList.parkDurationFMT}}</el-row>
        </el-row>
        <el-row class="lineRowItem">
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">应付金额：</el-row>
            <el-row class="lineRowContent"
                    style="color:#C09200">{{orderList.receivableMoneyAmount?Number(Number(orderList.receivableMoneyAmount)/100).toFixed(2):0}}元</el-row>
          </el-col>
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">是否缴费：</el-row>
            <el-row class="lineRowContent">{{orderList.orderStatus}}</el-row>
          </el-col>
        </el-row>
        <el-row class="lineRowItem">
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">实收金额：</el-row>
            <el-row class="lineRowContent"
                    style="color:#C09200">{{orderList.receivedMoneyAmount?Number(Number(orderList.receivedMoneyAmount)/100).toFixed(2):0}}元</el-row>
          </el-col>
          <el-col :span="12"
                  style="display:flex">
            <el-row class="lineRowTitle">优惠金额：</el-row>
            <el-row class="lineRowContent">0元</el-row>
          </el-col>
        </el-row>
        <el-row class="lineRowItem">
          <el-row class="lineRowTitle">选择出入口</el-row>
          <el-row class="lineRowContent"
                  style="padding-left:20px">
            <el-select filterable
                       v-model="videoOrderInOut"
                       placeholder="全部"
                       style="width:230px"
                       @change="videoOrderInOutChange">
              <el-option v-for="(item, index) in videoOrderInOutList"
                         :key="index"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </el-row>
        </el-row>
        <el-row class="lineRowItem"
                style="margin-top:30px">
          <el-button type="primary"
                     @click="handleClickShoufeiQrCode"
                     :disabled=inoutButtonDisable>收费二维码</el-button>
          <el-button type="primary"
                     style="position: absolute;right:20px"
                     :disabled=inoutButtonDisableLingyuan
                     @click="handleClickFangxing">0元放行</el-button>
        </el-row>
        <el-row class="bottomButton">
          <el-button type="primary"
                     class="bottomButtonLeft"
                     :disabled=inoutButtonDisableLingyuan
                     @click="handleClickAlterIn(orderList)">修改进场</el-button>
          <el-button class="bottomButtonMiddle"
                     @click="handleClickOpenGate">抬 杆</el-button>
          <el-button class="bottomButtonRight"
                     @click="handleClickCloseGate">落 杆</el-button>
        </el-row>
      </el-row>
    </el-row>
    <el-row class="lineTwo">
      <el-row class="lineTwoLeft">
        <el-row class="lineOneTop"></el-row>
        <el-row class="lineTwoLeftTitle">
          <span class="lineTwoLeftTitleLeft">停车场出口:</span>
          <el-select style="width:256px;margin-left:10px"
                     v-model="bottomLeft.name"
                     placeholder="全部">
            <el-option v-for="(item, index) in bottomLeftList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <el-row class="bottomButton"
                  style="right:15%">
            <el-button type="primary"
                       @click="handleClickQueryOutOrder">出口车辆订单查询</el-button>
          </el-row>
          <el-row class="bottomButton">
            <el-button type="primary"
                       :disabled=inoutButtonDisableLingyuan
                       @click="handleClickAlterOut">离场补录</el-button>
          </el-row>
        </el-row>
        <el-row class="lineTwoLeftContent">
          <el-image style="width:100%;height:100%;"
                    v-if="bottomLeftImage"
                    :src="$imgBaseUrl + bottomLeftImage"
                    :preview-src-list="bottomLeftImageList"
                    :fit="fit">
          </el-image>
          <div v-else
               style="width:100%;height:100%;display: flex; align-items: center;justify-content: center;">暂无图片</div>
        </el-row>
      </el-row>
      <el-row class="lineTwoRight">
        <el-row class="lineOneTop"></el-row>
        <el-row class="lineTwoRightTitle">
          <el-select v-model="videoType"
                     placeholder="请选择"
                     style="width:135px"
                     @change="handleChangeVideoTypeNext">
            <el-option v-for="(item, index) in videoTypeList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <el-select v-model="videoTypeNext"
                     placeholder="请选择"
                     style="width:256px;margin-left:10px"
                     @change="handleChangeVideoLast">
            <el-option v-for="(item, index) in videoTypeNextList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </el-row>
        <el-row class="lineTwoLeftContent">
          <canvas id="video-canvas"
                  style="width:100%;height:100%"></canvas>
        </el-row>
      </el-row>
    </el-row>
    <!-- 抬杆备注 -->
    <el-dialog :title='`${videoOrderInOutDui.name} 抬杆备注`'
               :visible.sync="openDialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form :model="openFormList"
               :inline="true"
               ref="openFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="openFormList.abnormalLiftReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickOpenConfirm()">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="openDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 落杆备注 -->
    <el-dialog :title='`${videoOrderInOutDui.name} 落杆备注`'
               :visible.sync="closeDialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form :model="closeFormList"
               :inline="true"
               ref="openFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="closeFormList.abnormalLiftReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickCloseConfirm()">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="closeDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改进场 -->
    <el-dialog title="修改进场"
               :visible.sync="inDialogVisible"
               append-to-body>
      <el-form :model="inForm"
               ref="form">
        <el-row style="margin-bottom:20px;display:flex">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          label-width="95px">
              <el-input v-model="inForm.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          label-width="95px">
              <el-select clearable
                         v-model="inForm.plateColor"
                         @change="handleChangePlateColorIn"
                         placeholder="请选择">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="进场时间"
                        label-width="95px">
            <el-date-picker v-model="inForm.entranceTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="inFormSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="inDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 进场补录 -->
    <el-dialog title="进场补录"
               :visible.sync="comeDialogVisible"
               append-to-body>
      <el-form :model="comeForm"
               ref="form">
        <el-row style="margin-bottom:20px;display:flex">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          label-width="95px">
              <el-input v-model="comeForm.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          label-width="95px">
              <el-select clearable
                         v-model="comeForm.numberPlateColorCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="进场时间"
                        label-width="95px">
            <el-date-picker v-model="comeForm.entranceTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <span class="span">进场图片</span>
          <el-upload v-if="!comeForm.evidenceImgUrl"
                     class="avatar-uploader"
                     action=""
                     accept="image/*"
                     :http-request="upLoadPicCome"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccessCome"
                     :before-upload="beforeAvatarUpload"
                     :limit="1">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div v-else>
            <el-button size="small"
                       type="primary"
                       @click="deleteClickCome">删除</el-button>
            <el-image :src="$imgBaseUrl + comeForm.evidenceImgUrl"></el-image>
          </div>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="comeFormSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="comeDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 离场补录 -->
    <el-dialog title="离场补录"
               :visible.sync="outDialogVisible"
               append-to-body>
      <el-form :model="outForm"
               ref="form">
        <el-row style="margin-bottom:20px;display:flex">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          label-width="95px">
              <el-input v-model="outForm.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          label-width="95px">
              <el-select clearable
                         v-model="outForm.plateColor"
                         @change="handleChangePlateColorOut"
                         placeholder="请选择">
                <el-option v-for="(item, index) in plateColorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="离场时间"
                          label-width="95px">
              <el-date-picker v-model="outForm.leaveTime"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <span class="span">离场图片</span>
          <el-upload v-if="!outForm.evidenceImgUrl"
                     class="avatar-uploader"
                     action=""
                     accept="image/*"
                     :http-request="upLoadPic"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload"
                     :limit="1">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div v-else>
            <el-button size="small"
                       type="primary"
                       @click="deleteClick">删除</el-button>
            <el-image :src="$imgBaseUrl + outForm.evidenceImgUrl"></el-image>
          </div>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="outFormSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="outDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 0元放行 -->
    <el-dialog :title='`给 ${zeroFangxingList.orderSequence}订单 0元放行`'
               :visible.sync="zeroDialogVisible"
               append-to-body>
      <el-form :model="zeroForm"
               ref="form">
        <el-row style="margin-bottom:20px;display:flex">
          <el-col :span="12">
            <el-form-item label="备注"
                          label-width="95px">
              <el-input v-model="zeroForm.bak"
                        type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="zeroFormSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="zeroDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 出口车辆订单查询 -->
    <el-dialog title="出口车辆订单详情"
               close-on-click-modal
               :visible.sync="outOrderDialogVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               width="50%">
      <div style="display:flex">
        <!-- 弹窗左侧 -->
        <el-row style="margin-top:25px;">
          <el-row>
            <span :span="8"
                  class="span-title">订单编号:</span>
            <span :span="16"
                  class="span-text">{{bottomLeftOrderList.orderSequence}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span style="color:white;font-size:19px">{{bottomLeftOrderList.plateNumber}}</span>
          </el-row>
          <el-row style="margin-top:30px">
            <span :span="24"
                  class="span-title">停车时长</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span style="font-size:30px;color:#08F7E5">{{bottomLeftOrderList.parkDuration}}</span>
          </el-row>
          <el-row style="margin-top:30px">
            <span :span="8"
                  class="span-title">订单时间:</span>
            <span :span="16"
                  class="span-text"> {{bottomLeftOrderList.orderCreateTime}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span :span="8"
                  class="span-title">申请方式:</span>
            <span :span="16"
                  class="span-text"> {{bottomLeftOrderList.orderStatus}}</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span :span="8"
                  class="span-title">实付金额:</span>
            <span :span="16"
                  style="color:#C29300;font-size=16px"> {{bottomLeftOrderList.receivedMoneyAmount}}元</span>
          </el-row>
          <el-row style="margin-top:20px">
            <span :span="8"
                  class="span-title">应付金额:</span>
            <span :span="16"
                  style="color:#C29300;font-size=16px"> {{bottomLeftOrderList.receivableMoneyAmount}}元</span>
          </el-row>
          <el-row style="margin-top:20px"
                  v-if="bottomLeftOrderList.remark">
            <span :span="8"
                  class="span-title">备注:</span>
            <span :span="16"
                  style="color:#C29300;font-size=16px"> {{bottomLeftOrderList.remark}}</span>
          </el-row>
        </el-row>
        <!-- 右侧 -->
        <el-row style="margin-left:100px;display:flex;margin-bottom:10px;margin-top:10px">
          <el-row style="border:1px solid #0F91DE;height:390px;width:240px">
            <el-row style=" height:40px;line-height:40px;display:flex;  justify-content: space-around;">
              <el-row style="color:#6DE920">入场</el-row>
              <el-row style="color:white">{{bottomLeftOrderList.entranceTime}}</el-row>
            </el-row>
            <el-row style="height:350px">
              <el-image style="width:100%;height:100%;"
                        v-if="outOrderInPic.evidencePic !==undefined"
                        :src="$imgBaseUrl + outOrderInPic.evidencePic"
                        :preview-src-list="outOrderInPicList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="outOrderInPic.evidencePic">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
          <el-row style="margin-left: 35px; border: 1px solid rgb(15, 145, 222); height: 390px;width: 240px;">
            <el-row style="height:40px;line-height:40px;display:flex;justify-content: space-around;">
              <el-row style="color:#F98D0B">出场</el-row>
              <el-row style="color:white"
                      v-if="bottomLeftOrderList.leaveTime">{{bottomLeftOrderList.leaveTime}}</el-row>
              <el-row style="color:white"
                      v-else>暂未出场</el-row>
            </el-row>
            <el-row style="height:350px">
              <el-image style="width:100%;height:100%"
                        v-if="outOrderOutPic.evidencePic !==undefined"
                        :src="$imgBaseUrl + outOrderOutPic.evidencePic"
                        :preview-src-list="outOrderOutPicList"
                        :fit="fit">
                <el-row slot="error"
                        style="height:100%;text-align:center">
                  <el-row style="height:100%;margin-top:145px;color:white">
                    <span v-if="outOrderOutPic.evidencePic">加载失败</span>
                    <span v-else>该图片未上传</span>
                  </el-row>
                </el-row>
              </el-image>
            </el-row>
          </el-row>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import parkStopTime from '@/utils/parkStopTime'
import Compressor from 'compressorjs'
import webStomp from 'webstomp-client';
import SockJS from 'sockjs-client'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      fit: 'fill',
      parkNameList: [], // 选择停车场列表
      topParkId: "", // 顶部停车场选择绑定
      topParkIdDisable: false,
      entranceTime: [], // 顶部查询进场时间
      entranceStartTime: "",// 查询进场时间开始时间
      entranceEndTime: "",// 查询进场时间结束时间
      plateNumber: "",// 顶部查询车牌号
      orderList: {}, // 车位在线每项订单
      insrcList: [],// 左侧预览图片
      inEvidencePic: "",//左侧进场图片
      videoType: "",// 右下角左侧下拉框
      videoTypeList: [{ code: 1, name: "环境监控" }, { code: 2, name: "卡口监控" }],
      videoTypeNext: "",// 右下角右侧下拉框
      videoTypeNextList: [], // 右下角右侧下拉框
      videoOrderInOut: "",// 订单中的选择出入口
      videoOrderInOutDui: {},//订单中的选择出入口 videotype name code
      videoOrderInOutList: [],// 订单中的选择出入口列表
      inoutButtonDisable: true,// 收费二维码按钮不可点击
      inoutButtonDisableLingyuan: true,//0元放行按钮不可点击

      plateColorList: [], // 车牌颜色
      inDialogVisible: false,// 修改进场弹窗
      inForm: {}, // 修改进场表单
      inAlterColor: [],
      comeDialogVisible: false,// 进场补录弹窗
      comeForm: {},// 进场补录表单
      comeAlterColor: [],
      outDialogVisible: false,// 离场补录弹窗
      outForm: {},// 离场补录表单
      outAlterColor: [],

      zeroDialogVisible: false,// 0元放行
      zeroForm: {},// 0元放行
      zeroFangxingList: {},// 零元放行列表

      openDialogVisible: false, // 抬杆
      openFormList: {},
      closeDialogVisible: false,// 落杆
      closeFormList: {},
      parkGateList: {},// 顶部停车场保存

      carIn: "/carIn",  // 入场消息的topic
      carOut: "/carOut",// 出场消息的topic
      stompClient: null,
      carInList: {}, // 入场socket内容
      carOutList: {}, // 出场socket内容

      middleOrderList: [], // 中间显示订单列表

      bottomLeft: {}, // 底部左侧出口
      bottomLeftList: [], // 底部左侧出口选择器列表
      bottomLeftImage: "",// 底部左侧出口图片
      bottomLeftImageList: [],// 底部左侧出口图片预览

      outOrderDialogVisible: false,// 出口车辆订单详情弹窗
      bottomLeftInfoList: {},// 底部左侧推送参数
      bottomLeftOrderList: {},// 底部左侧订单
      outOrderInPic: {},
      outOrderInPicList: [],
      outOrderOutPic: {},
      outOrderOutPicList: [],

      videoUrl: "", // 视频地址
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
      carInSub: null,
      carOutSub: null
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryPlateColorList()
    this.queryParkNameList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.socketConnect()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
    this.carInSub.unsubscribe()
    this.carOutSub.unsubscribe()
    this.stompClient.disconnect()
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 查询左下角停车场出口列表
    queryBottomLeftOutList (val) {
      this.bottomLeft = {}
      let info = {
        columnName: ['device_id', 'device_name'],
        tableName: 'tb_video',
        whereStr: [
          // 卡口监控
          { colName: 'video_type', value: '2' },
          { colName: 'park_id', value: val },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.bottomLeftList = response.resultEntity
        if (response.resultEntity[0]) {
          this.bottomLeft.name = response.resultEntity[0].name
          this.bottomLeft.code = response.resultEntity[0].code
          // 根据出口相机编号获取底部左侧出口图片
          let info = {
            deviceId: response.resultEntity[0].code
          }
          this.$parkingRegulation.getGateRealtimePic(info).then(res => {
            if (res.resultEntity) {
              this.bottomLeftImage = JSON.parse(res.resultEntity).evidenceImgUrl
              this.bottomLeftImageList.push(this.$imgBaseUrl + this.bottomLeftImage)
              this.bottomLeftInfoList = JSON.parse(res.resultEntity)
            }
          })
        }
      })
    },
    // 点击出口车辆订单查询
    handleClickQueryOutOrder () {
      this.bottomLeftOrderList = {}
      this.queryBottomLeftImage()
      this.outOrderOutPic = {}
      this.outOrderOutPicList = []
      if (!this.bottomLeftImage) {
        // 如果没有图片推送无法查询订单
        this.$message.warning("暂无图片")
      } else {
        // 有图片
        const info = {
          parkId: this.bottomLeftInfoList.parkId,
          plateNumber: this.bottomLeftInfoList.plateNumber,
          numberPlateColorCode: this.bottomLeftInfoList.numberPlateColorCode,
          pageNum: 0,
          pageSize: 1,
        }
        // 查询订单
        this.$parkingRegulation.getOrderByOrderStatus(info).then(response => {
          let that = this
          let currentTime = new Date().Format("yyyy-MM-dd hh:mm:ss") // 当前时间
          if (response.resultEntity.content[0]) {
            let info1 = {
              orderSequence: response.resultEntity.content[0].orderSequence
            }
            that.$parkingRegulation.charge(info1).then(res => {
              if (res.resultEntity.leaveTime) {
                res.resultEntity.parkDuration = parkStopTime(res.resultEntity.leaveTime, res.resultEntity.entranceTime)
              } else {
                res.resultEntity.parkDuration = parkStopTime(currentTime, res.resultEntity.entranceTime)
              }
              res.resultEntity.receivedMoneyAmount =
                Number(Number(res.resultEntity.receivedMoneyAmount) / 100).toFixed(2)
              res.resultEntity.receivableMoneyAmount =
                Number(Number(res.resultEntity.receivableMoneyAmount) / 100).toFixed(2)

              that.bottomLeftOrderList = res.resultEntity
              // 查询取证图片
              let _that = that
              let info2 = {
                orderSequence: res.resultEntity.orderSequence
              }
              _that.$queryDict.getEvidenceByOrderSequence(info2).then(res2 => {
                if (res2.resultEntity == '' || res2.resultEntity == undefined) {
                  _that.$error
                } else if (res2.resultEntity.length !== 0) {
                  res2.resultEntity.forEach(item => {
                    if (item.eventType === 1) {
                      _that.outOrderInPic = item
                      _that.outOrderInPicList.push(_that.$imgBaseUrl + _that.outOrderInPic.evidencePic)
                    } else if (item.eventType === 3) {
                      _that.outOrderOutPic = item
                      _that.outOrderOutPicList.push(_that.$imgBaseUrl + _that.outOrderOutPic.evidencePic)
                    }
                  })
                }
                _that.outOrderDialogVisible = true
              })
            })

          } else {
            this.$message.warning("暂未查到该订单")
          }
        })
      }
    },
    // webSocket根据停车场渲染左下角的图片
    queryBottomLeftImage () {
      if (this.topParkId === this.carOutList.parkId) {
        this.bottomLeftImage = this.carOutList.evidenceImgUrl
        this.bottomLeftImageList.push(this.$imgBaseUrl + this.bottomLeftImage)
        this.bottomLeftInfoList = this.carOutList

        // this.topSelectChange(this.topParkId)
      }
    },
    // socket连接
    socketConnect () {
      // socket地址
      let socket = new SockJS("/cqparkApi/message-push/ws")
      // 可以手动在localStorage设置值开启debug模式
      let isDebug = localStorage.getItem("stomp-js-debug")
      // 创建客户端
      this.stompClient = webStomp.over(socket, {
        heartbeat: false,
        debug: isDebug ? isDebug : false,
        protocols: webStomp.VERSIONS.supportedProtocols()
      });
      // 连接
      this.stompClient.connect({}, frame => {
        console.log("连接成功", frame);
        // 订阅入场消息
        this.carInSub = this.stompClient.subscribe(this.carIn, message => {
          // 在此处理入场消息 message.body
          console.log(this.carIn + "消息:", JSON.parse(message.body))
          this.carInList = JSON.parse(message.body)
          this.queryMiddleList()
        })
        // 订阅出场消息
        this.carOutSub = this.stompClient.subscribe(this.carOut, message => {
          // 在此处理出场消息 message.body
          console.log(this.carOut + "消息:", JSON.parse(message.body))
          this.carOutList = JSON.parse(message.body)
          this.queryBottomLeftImage()
        })
      }, error => {
        console.error("连接出错", error)
      });
    },
    // webSocket推送中间列表内容
    queryMiddleList () {
      // 如果推送过来的消息是当前选择停车场，需要重新获取中间部分的订单
      if (this.topParkId === this.carInList.parkId) {
        this.addMiddleList(this.carInList)
      }
    },
    // 往进场里面加个内容
    addMiddleList (val) {
      let info = {
        parkId: val.parkId,
        plateNumber: val.plateNumber
      }
      this.$parkingRegulation.getOrderOnlineNoPageWithEvidence(info).then(response => {
        // 有或者没有订单
        let list = this.middleOrderList
        if (response.resultEntity[0]) {
          if (response.resultEntity[0].orderEvidenceList[0]) {
            response.resultEntity[0].evidencePic = response.resultEntity[0].orderEvidenceList[0].evidencePic
          }
          // 把新订单放到在停订单中
          list.splice(0, 0, response.resultEntity[0])
          this.middleOrderList = new Set(list)
        }
      })
    },
    // 进场时间修改 查询订单
    handleChangeEntranceTime () {
      if (this.entranceTime) {
        this.entranceStartTime = this.entranceTime[0]
        this.entranceEndTime = this.entranceTime[1]
      } else {
        this.entranceStartTime = ''
        this.entranceEndTime = ''
      }
      this.topSelectChange(this.topParkId)
    },
    // 车牌号查询 查询订单
    handleChangePlateNumebr () {
      if (this.plateNumber.length !== 0) {
        this.topParkIdDisable = true
        this.inEvidencePic = ""
        this.insrcList = []
        let info = {
          plateNumber: this.plateNumber
        }
        this.$parkingRegulation.getOrderOnlineNoPageWithEvidence(info).then(response => {
          response.resultEntity.forEach(item => {
            if (item.orderEvidenceList[0]) {
              item.evidencePic = item.orderEvidenceList[0].evidencePic
            }
          })
          this.middleOrderList = response.resultEntity
          // 零元放行得有订单
          if (response.resultEntity.length == 0) {
            this.inoutButtonDisable = true
            this.inoutButtonDisableLingyuan = true
          } else if (response.resultEntity.length != 0) {
            this.inoutButtonDisableLingyuan = false
          }
          // 默认显示第一条订单
          if (response.resultEntity[0]) {
            this.inEvidencePic = response.resultEntity[0].evidencePic
            this.insrcList.push(this.$imgBaseUrl + response.resultEntity[0].evidencePic)
            this.handleClickParkSpaceItem(response.resultEntity[0])
          }
          this.topParkId = response.resultEntity[0].parkId
          this.queryOrderInOutChange(this.topParkId)
          // 切换获取出入口
          this.queryBottomLeftOutList(this.topParkId)
          // 获取环境监控卡口监控
          this.handleChangeVideoTypeNext(this.topParkId)
        })
      } else {
        this.topParkIdDisable = false
        this.topSelectChange(this.topParkId)
      }
    },
    // 查询停车场列表
    queryParkNameList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
        whereStr: [
          { colName: 'park_type_code', value: '2' },
        ],
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
        // 获取到顶部停车场的编号
        this.topParkId = this.parkNameList[0].code
        // 默认显示顶部第一个停车场
        this.topSelectChange(this.parkNameList[0].code)
        // 默认显示获取出入口
        this.queryBottomLeftOutList(this.parkNameList[0].code)
      })
    },
    // 顶部选择停车场切换
    topSelectChange (val) {
      this.inEvidencePic = ""
      this.insrcList = []
      this.orderList = {}
      this.middleOrderList = []
      this.videoOrderInOut = ""
      this.videoOrderInOutDui = {}
      // 切换获取订单出入口
      this.queryOrderInOutChange(this.topParkId)
      // 切换获取出入口
      this.queryBottomLeftOutList(this.topParkId)
      // 获取环境监控卡口监控
      this.handleChangeVideoTypeNext(this.topParkId)
      // 获取停车场在线订单
      let info = {
        parkId: val,
        entranceStartTime: this.entranceStartTime,
        entranceEndTime: this.entranceEndTime,
        plateNumber: this.plateNumber
      }
      this.$parkingRegulation.getOrderOnlineNoPageWithEvidence(info).then(response => {
        response.resultEntity.forEach(item => {
          if (item.orderEvidenceList[0]) {
            item.evidencePic = item.orderEvidenceList[0].evidencePic
          }
        })
        this.middleOrderList = response.resultEntity
        // 零元放行得有订单
        if (this.middleOrderList.length == 0) {
          this.inoutButtonDisable = true
          this.inoutButtonDisableLingyuan = true
        }
        else if (this.middleOrderList.length != 0) {
          this.inoutButtonDisableLingyuan = false
        }
        // 默认显示第一条订单
        if (this.middleOrderList[0]) {
          this.inEvidencePic = this.middleOrderList[0].evidencePic
          this.insrcList.push(this.$imgBaseUrl + this.middleOrderList[0].evidencePic)
          this.handleClickParkSpaceItem(this.middleOrderList[0])
        }
      })
    },
    // 点击车位在线订单 获取订单详情
    handleClickParkSpaceItem (item) {
      this.insrcList = []
      this.inEvidencePic = item.evidencePic
      this.insrcList.push(this.$imgBaseUrl + item.evidencePic)
      let currentTime = new Date().Format("yyyy-MM-dd hh:mm:ss") // 当前时间
      item.parkDurationFMT = parkStopTime(currentTime, item.entranceTime)
      this.rechargeBill(item)
      this.orderList = item
    },
    // 改单后重新计费
    rechargeOrderBill (val) {
      let info = {
        orderSequence: val.orderSequence
      }
      this.$parkingRegulation.chargeOrder(info).then(() => {
        let that = this
        that.rechargeBill(val)
      })
    },
    // 重新计费
    rechargeBill (val) {
      let info = {
        orderSequence: val.orderSequence
      }
      this.$parkingRegulation.charge(info).then(res => {
        this.orderList.receivableMoneyAmount =
          res.resultEntity.receivableMoneyAmount
      })
    },
    // 获取订单中的选择出入口切换
    videoOrderInOutChange (val) {
      this.videoOrderInOutList.forEach(item => {
        if (item.code === val) {
          this.videoOrderInOutDui = item
        }
      })
      if (this.videoOrderInOutDui.videoType == 1) {
        this.inoutButtonDisable = true
      }
      else if (this.videoOrderInOutDui.videoType == 2) {
        this.inoutButtonDisable = false
      }
    },
    // 获取订单中的选择出入口列表
    queryOrderInOutChange (val) {
      this.videoOrderInOut = ""
      this.videoOrderInOutDui = {}
      this.videoOrderInOutList = []
      let info = {
        parkId: val,
        videoTypeList: ["1", "2"],
        pageNum: 1,
        pageSize: 5
      }
      this.$bayonetMonitor.queryVideoList(info).then(res => {
        res.resultEntity.list.forEach(item => {
          this.videoOrderInOutList.push({ name: item.deviceName, code: item.deviceId, videoType: item.videoType })
        })
      })
    },
    // 点击收费二维码
    handleClickShoufeiQrCode () {
      if (this.orderList.orderSequence) {
        let info = {
          orderSequence: this.orderList.orderSequence
        }
        this.$parkingRegulation.charge(info).then(() => {
          let that = this
          // 无牌车
          if (that.orderList.plateNumber.includes('临时车') || that.orderList.plateNumber === '无牌车') {
            let info2 = {
              deviceId: that.videoOrderInOutDui.code,
            }
            that.$confirm("是否确定给" + that.videoOrderInOutDui.name + "发布二维码?", '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              that.$parkingRegulation.noPlateQrCodeShoufei(info2).then(() => {
                that.$message({ message: '发布成功', type: 'success' })
              })
            }).catch(() => {
              that.$message({ message: '发布已取消', type: 'info' })
            })
          }
          // 有牌车
          else {
            let info1 = {
              deviceId: that.videoOrderInOutDui.code,
              orderSequence: that.orderList.orderSequence
            }
            that.$confirm("是否确定给" + that.videoOrderInOutDui.name + "发布二维码?", '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              that.$parkingRegulation.qrCodeShoufei(info1).then(() => {
                that.$message({ message: '发布成功', type: 'success' })
              })
            }).catch(() => {
              that.$message({ message: '发布已取消', type: 'info' })
            })
          }
        })
      }
    },
    // 点击0元放行
    handleClickFangxing () {
      this.zeroForm = {}
      this.zeroFangxingList = {}
      this.zeroFangxingList = this.orderList
      this.zeroDialogVisible = true
    },
    // 点击0元放行确认
    zeroFormSubmit () {
      this.zeroFangxingList.bak = this.zeroForm.bak
      let info = {
        orderSequence: this.zeroFangxingList.orderSequence,
        bak: this.zeroFangxingList.bak
      }
      this.$commonAxios.makeOrderFree(info).then(() => {
        this.$message({ message: '放行成功', type: 'success' })
        this.handleClickParkSpaceItem(this.zeroFangxingList)
        this.zeroDialogVisible = false
        // 自动抬杆
        // let that = this
        // let info = {
        //   deviceId: that.videoOrderInOut //不能为空
        // }
        // that.$bayonetMonitor.openGate(info).then(() => {
        //   that.$message({ message: '放行成功', type: 'success' })
        //   that.handleClickParkSpaceItem(this.zeroFangxingList)
        // })
      })
    },
    // 修改进场
    handleClickAlterIn () {
      this.inDialogVisible = true
      this.inForm = this.orderList
    },
    // 改单中的颜色发生变化
    handleChangePlateColorIn (val) {
      this.inAlterColor = this.plateColorList.filter(item => {
        if (item.code == val) {
          return item
        }
      })
    },
    // 修改进场确认
    inFormSubmit () {
      if (this.inAlterColor.length !== 0) {
        this.inForm.numberPlateColorCode = Number(this.inAlterColor[0].code)
        this.inForm.plateColor = this.inAlterColor[0].name
      }
      let info = {
        orderSequence: this.inForm.orderSequence,
        plateNumber: this.inForm.plateNumber,
        numberPlateColorCode: this.inForm.numberPlateColorCode,
        entranceTime: this.inForm.entranceTime
      }
      this.$commonAxios.editOrderForComeV2(info).then(() => {
        this.$message({ message: '修改成功', type: 'success' })
        this.inDialogVisible = false
        this.rechargeOrderBill(this.inForm)
        // this.handleClickParkSpaceItem(this.inForm)
        // this.queryParkNameList()
      })
    },
    // 进场补录
    handleClickAlterCome () {
      this.comeDialogVisible = true
      this.comeForm = {}
    },
    // 进场上传图片
    upLoadPicCome (file) {
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            self.comeForm.evidenceImgUrl = res.resultEntity
            self.$forceUpdate()
          }).catch(err => {
            console.log('图片上传失败', err)
          })
        },
        error (err) {
          console.log('压缩失败', err)
        }
      })
    },
    // 删除
    deleteClickCome () {
      this.comeForm.evidenceImgUrl = ''
      this.$forceUpdate()
    },
    // 上传图片
    handleAvatarSuccessCome (res, file) {
      this.comeForm.link = URL.createObjectURL(file.raw);
    },
    // 进场补录确认
    comeFormSubmit () {
      let info = {
        parkId: this.topParkId,
        plateNumber: this.comeForm.plateNumber,
        numberPlateColorCode: this.comeForm.numberPlateColorCode,
        entranceTime: this.comeForm.entranceTime,
        evidenceImgUrl: this.comeForm.evidenceImgUrl,
      }
      this.$commonAxios.supplementOrderForCome(info).then(() => {
        this.$message({ message: '进场补录成功', type: 'success' })
        this.comeDialogVisible = false
        // this.handleClickParkSpaceItem(this.comeForm)
        // this.queryParkNameList()
      })
    },
    // 离场补录
    handleClickAlterOut () {
      this.outDialogVisible = true
      this.outForm = this.orderList
    },
    // 离场补录颜色确认
    handleChangePlateColorOut (val) {
      this.outAlterColor = this.plateColorList.filter(item => {
        if (item.code == val) {
          return item
        }
      })
    },
    // 离场上传图片
    upLoadPic (file) {
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            self.outForm.evidenceImgUrl = res.resultEntity
            self.$forceUpdate()
          }).catch(err => {
            console.log('图片上传失败', err)
          })
        },
        error (err) {
          console.log('压缩失败', err)
        }
      })
    },
    // 删除
    deleteClick () {
      this.outForm.evidenceImgUrl = ''
      this.$forceUpdate()
    },
    // 上传图片
    handleAvatarSuccess (res, file) {
      this.outForm.link = URL.createObjectURL(file.raw);
    },
    // 提示
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG / PNG 格式!');
        return false
      }
      return true
    },
    // 离场补录确认
    outFormSubmit () {
      if (this.outAlterColor.length !== 0) {
        this.outForm.numberPlateColorCode = Number(this.outAlterColor[0].code)
        this.outForm.plateColor = this.outAlterColor[0].name
      }
      let info = {
        orderSequence: this.outForm.orderSequence,
        plateNumber: this.outForm.plateNumber,
        numberPlateColorCode: this.outForm.numberPlateColorCode,
        leaveTime: this.outForm.leaveTime,
        evidenceImgUrl: this.outForm.evidenceImgUrl,
      }
      this.$commonAxios.supplementOrderForLeave(info).then(() => {
        this.$message({ message: '离场补录成功', type: 'success' })
        this.outDialogVisible = false
        // this.handleClickParkSpaceItem(this.outForm)
        // this.queryParkNameList()
      })
    },
    // 环境监控卡口监控切换
    handleChangeVideoTypeNext (val) {
      this.videoTypeNextList = []
      // 环境监控卡口监控查询
      if (val === 1) {
        // 环境
        let info = {
          parkId: this.topParkId,
          videoTypeList: ["3", "4"],
          pageNum: 1,
          pageSize: 5
        }
        this.$posDev.queryVideoList(info).then(res => {
          res.resultEntity.list.forEach(item => {
            this.videoTypeNextList.push({ name: item.deviceName, code: item.deviceId })
          })
        })
      } else if (val === 2) {
        // 卡口
        let info = {
          parkId: this.topParkId,
          videoTypeList: ["1", "2"],
          pageNum: 1,
          pageSize: 5
        }
        this.$bayonetMonitor.queryVideoList(info).then(res => {
          res.resultEntity.list.forEach(item => {
            this.videoTypeNextList.push({ name: item.deviceName, code: item.deviceId })
          })
        })
      }
    },
    // 右下角第二个下拉框
    handleChangeVideoLast (val) {
      console.log(val);
      // let info = {
      //   deviceId: val
      // }
      // this.$parkingRegulation.getVideUrl(info).then(res => {
      //   this.$axios.post("http://localhost:3000/stream/play", { url: res }
      //   ).then(response => {
      //     var canvas = document.getElementById('video-canvas');
      //     this.videoUrl = 'ws://localhost:3000' + response.data.data.path
      //     new window.JSMpeg.Player(this.videoUrl, { canvas: canvas });
      //   }).catch(() => {
      //     this.$message({ message: '请联系运维人员，安装插件！', type: 'warning' })
      //   })
      // })
    },
    // 获取视频播放地址
    // getVideUrl () { },
    // 抬杆
    handleClickOpenGate () {
      this.openFormList = {}
      this.parkGateList = {}
      if (this.videoOrderInOutDui.code != undefined) {
        this.parkNameList.forEach(item => {
          if (this.topParkId === item.code) {
            this.parkGateList = item
          }
        })
        this.openDialogVisible = true
      } else {
        this.$message({ message: '请先选择出入口', type: 'info' })
      }
    },
    // 抬杆确认
    handleClickOpenConfirm () {
      let that = this
      let info = {
        deviceId: this.videoOrderInOutDui.code,
        deviceName: this.videoOrderInOutDui.name,
        parkId: this.parkGateList.code,
        parkName: this.parkGateList.name,
        abnormalLiftReason: this.openFormList.abnormalLiftReason
      }
      this.$confirm("是否确定给" + this.videoOrderInOutDui.name + "抬杆?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$bayonetMonitor.openGate(info).then(() => {
          that.openDialogVisible = false
          that.$message({ message: '抬杆成功', type: 'success' })
        })
      }).catch(() => {
        that.$message({ message: '抬杆已取消', type: 'info' })
      })
    },
    // 落杆
    handleClickCloseGate () {
      this.closeFormList = {}
      this.parkGateList = {}
      if (this.videoOrderInOutDui.code != undefined) {
        this.parkNameList.forEach(item => {
          if (this.topParkId === item.code) {
            this.parkGateList = item
          }
        })
        this.closeDialogVisible = true
      }
      else {
        this.$message({ message: '请先选择出入口', type: 'info' })
      }
    },
    // 落杆确认
    handleClickCloseConfirm () {
      let that = this
      let info = {
        deviceId: this.videoOrderInOutDui.code,
        deviceName: this.videoOrderInOutDui.name,
        parkId: this.parkGateList.code,
        parkName: this.parkGateList.name,
        abnormalLiftReason: this.closeFormList.abnormalLiftReason
      }
      this.$confirm("是否确定给" + this.videoOrderInOutDui.name + "落杆?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$bayonetMonitor.closeGate(info).then(() => {
          that.closeDialogVisible = false
          that.$message({ message: '落杆成功', type: 'success' })
        })
      }).catch(() => {
        that.$message({ message: '落杆已取消', type: 'info' })
      })
    },
    // 获取车牌颜色
    queryPlateColorList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.plateColorList = response.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .lineOneTop {
    width: 100%;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(0, 238, 255, 0) 0%,
      #00eeff 47%,
      rgba(21, 111, 147, 0) 100%
    );
  }
  .lineOne {
    height: 58%;
    display: flex;
    background: radial-gradient(circle, #113342 0%, rgba(7, 17, 31, 0) 100%);

    .lineOneLeft {
      width: 50%;
      height: 100%;
      .lineOneLeftTitle {
        padding-top: 20px;
        padding-left: 20px;
        display: flex;
        .lineOneLeftTitleLeft {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 30px;
          color: #bae8ff;
        }
      }
      .lineOneLeftContent {
        height: calc(99% - 65px);
        width: 95%;
        margin-top: 15px;
        margin-left: 2%;
      }
    }
    .lineOneMiddle {
      width: 29%;
      height: 100%;
      padding-top: 20px;
      .lineOneLeftTitleLeft {
        font-size: 14px;
        font-family: PingFangSC-Regular;
        line-height: 30px;
        color: #bae8ff;
      }
      .parkSpaceDiv {
        width: 33%;
        .topImg {
          width: 90%;
          height: 115px;
        }
        .topImg:hover {
          border: 2px solid #fd7302;
        }
        .bottomText {
          width: 136px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          color: #bae8ff;
          text-align: center;
        }
      }
    }
    .lineOneRight {
      width: 21%;
      height: 100%;
      background: linear-gradient(180deg, rgba(7, 83, 121, 0) 0%, #032944 100%);
      padding-top: 20px;
      padding-left: 20px;
      .lineOneRightTitle {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-family: PingFangSC-Medium;
        line-height: 40px;
        color: #f8fdff;
      }
      .lineRowItem {
        width: 100%;
        height: 36px;
        font-size: 16px;
        display: flex;
        line-height: 50px;
        .lineRowTitle {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          color: #70aee8;
        }
        .lineRowContent {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          color: #bae8ff;
        }
      }
      .bottomButton {
        width: 100%;
        height: 32px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        .bottomButtonLeft {
        }
        .bottomButtonMiddle {
          width: 80px;
          height: 32px;
          background: radial-gradient(
            circle,
            #038aa5 0%,
            rgba(0, 0, 0, 0) 100%
          );
          border: 1px solid #038aa5;
          border-radius: 1px;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 0px;
          color: #ffffff;
          margin-left: 15%;
        }
        .bottomButtonRight {
          width: 80px;
          height: 32px;
          border: 1px solid #00708b;
          background: radial-gradient(
            circle,
            #0068b3 0%,
            rgba(0, 0, 0, 0) 100%
          );
          border-radius: 1px;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 0px;
          color: #00ebfb;
        }
      }
    }
  }
  .lineTwo {
    width: 100%;
    height: 41%;
    margin-top: 1%;
    display: flex;
    .lineTwoLeft {
      width: 50%;
      height: 100%;
      background: radial-gradient(circle, #113342 0%, rgba(7, 17, 31, 0) 100%);
      .lineTwoLeftTitle {
        padding-top: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        position: relative;
        .lineTwoLeftTitleLeft {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 0px;
          color: #bae8ff;
        }
        .bottomButton {
          float: right;
          position: absolute;
          right: 2%;
        }
      }
      .lineTwoLeftContent {
        width: 96%;
        height: 80%;
        margin-top: 15px;
        margin-left: 2%;
      }
    }
    .lineTwoRight {
      width: 50%;
      height: 100%;
      margin-left: 1%;
      background: radial-gradient(circle, #113342 0%, rgba(7, 17, 31, 0) 100%);
      .lineTwoRightTitle {
        padding-top: 20px;
        padding-left: 20px;
      }
      .lineTwoLeftContent {
        width: 100%;
        height: 80%;
        margin-top: 10px;
      }
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.span {
  display: inline-block;
  width: 100px;
  color: #70aee8;
  font-family: 'PingFang SC';
  font-weight: bold;
  padding-top: 20px;
}
.span-title {
  color: #79baf7;
  font-size: 16px;
}
.span-text {
  color: #789db7;
  font-size: 16px;
}
</style>